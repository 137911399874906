@use 'terra';
@import 'mixins';

textarea[ninetyInput].ninety-input {
  width: 100%; // Fill the width of its parent
  resize: vertical; // Allow vertical resizing only
  box-sizing: border-box; // Include padding and border in elements width/height

  overflow-wrap: break-word;
  overflow-y: auto;

  @include input-border();
  @include set-input-padding();
  @include terra.text(body);

  $padding-top-bottom: 7.5px;
  min-height: calc(2lh + (2 * $padding-top-bottom));

  // Experimental property that sets the height of a textarea to the real-time height of its content.
  // Causes textarea to expand as a user types. Works on chrome, not on firefox. ninetyAutoHeight will still work on
  // all browsers, but is not real time (ie: on load, it sets the height to the content, but doesn't update as the
  // user types).
  // [MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/field-sizing)
  // [Can I use](https://caniuse.com/?search=field-sizing)
  field-sizing: content;

  &.max-4lh {
    max-height: calc(4lh + (2 * $padding-top-bottom));
  }
}
