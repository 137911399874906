@use 'terra';

// repeat-todo-delete-dialog
.mat-mdc-dialog-container ninety-repeat-todo-delete-dialog {
  .mat-mdc-dialog-title {
    padding-bottom: 0;
  }
  .mdc-dialog__content {
    label {
      @include terra.text(body);
      color: terra.color('text');
    }
    .mdc-radio {
      padding: 0;
    }
  }
}

.cdk-overlay-container .cdk-overlay-pane:has(.team-dropdown-select) {
  top: 323px !important;
}

.cdk-overlay-container .cdk-overlay-pane:has(.team-datepicker) {
  top: 228px !important;
}

.ninety-button.show-pending-ab-todos-btn.selected button {
  background-color: terra.color('brand', 'shader-heavy') !important;
}

.detail-view.mdc-card {
  .item-details .mat-mdc-form-field .mdc-text-field {
    background-color: transparent;
    padding-inline: 0;

    .mat-mdc-select-arrow-wrapper {
      transform: none;
      .mat-mdc-select-arrow {
        margin: 0 4px;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0;
      .mat-mdc-icon-button {
        height: 35px;
        width: 21px;
        padding: 14px 0 0;
        .mat-ripple,
        .mat-mdc-focus-indicator,
        .mdc-icon-button__ripple {
          top: 15px;
          height: 24px;
        }
        .mat-mdc-button-touch-target {
          top: calc(50% + 15px);
          height: 48px;
        }

        &.cdk-program-focused .mat-mdc-button-persistent-ripple:before {
          opacity: 0 !important;
        }

        &.cdk-focus {
          opacity: 0;
        }
      }

      svg {
        width: 14px;
      }
    }
  }

  .mat-toolbar-single-row {
    padding-inline: 16px;
  }

  .rock-type-toggle {
    .mdc-elevation-overlay {
      background-color: terra.color('white');
    }
  }

  .mat-mdc-form-field.mat-focused {
    .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }
  }

  textarea.mat-mdc-input-element {
    padding: 2px 0;
    margin: -2px 0;
  }

  .mdc-line-ripple {
    &::before,
    &::after {
      bottom: 10px;
    }
  }

  .team-dropdown {
    .mat-mdc-form-field-flex {
      position: relative;
    }
    .mdc-line-ripple {
      position: absolute;
      bottom: calc(100% - 50px);
      width: 100%;
      border-bottom-width: var(--mdc-filled-text-field-focus-active-indicator-height);
      border-bottom-color: transparent;
      &::before,
      &::after {
        bottom: 0;
      }
    }
  }
}

ninety-todos {
  button.mat-mdc-icon-button[disabled] {
    opacity: 1 !important;
  }

  .list-title:has(~ ninety-inline-editable-due-date .inline-editable-due-date:hover) {
    ninety-inline-editable-title {
      .edit-btn {
        visibility: hidden !important;
      }
    }
  }

  .owner-avatar .user-avatar-div {
    position: relative;
  }
}

.detail-card-v2-wrp:has(> .ng-star-inserted ninety-todo-card-v2) {
  padding-bottom: 0px !important;
}
