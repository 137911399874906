/*
  Migrated flex styles from styles.scss.  There were duplciates.
  Left todo's for us to normalize these eventually and remove
  duplicates.
*/

// TODO: Migrate .dflex instances to .flex
.dflex,
.flex {
  display: flex;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-center-x-y-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-center-x-y-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}

// TODO: Duplicates and conflicts with definition on line 147
.flex-align-center {
  margin: auto;
  align-self: center;
}

.flex-align-left {
  margin-right: auto;
  align-self: center;
}

.flex-align-right {
  margin-left: auto;
  align-self: center;
}

.flex-align-x-center {
  margin-right: auto;
  margin-left: auto;
}

.flex-align-x-left {
  margin-right: auto;
}

.flex-align-x-right {
  margin-left: auto;
}

.flex-align-y-center {
  align-self: center;
}

.flex-align-y-top {
  align-self: flex-start;
}

.flex-align-y-bottom {
  align-self: flex-end;
}

.flex-basic {
  flex: 1 1 0%;
}

.fill-parent {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

.flex-layout-row {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
}

// TODO: replace .flex-column with flex-layout-column
.flex-column,
.flex-layout-column {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}

.flex-layout-row-wrap {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
}

// TODO: replace .flex-between with .flex-justify-space-between
.flex-between,
.flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-justify-start {
  justify-content: flex-start;
}

// TODO: Replace .flex-center with .flex-justify-center
.flex-center,
.flex-justify-center {
  display: flex;
  justify-content: center;
}

// TODO: Replace .flex-end with .flex-justify-end
.flex-end,
.flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

// TODO: Replace .align-items-center with .flex-align-center
.flex-align-center,
.align-items-center {
  display: flex;
  align-items: center;
}

.flex1 {
  flex: 1 !important;
}

.flex2 {
  flex: 2 !important;
}

.flex3 {
  flex: 3 !important;
}

.flex4 {
  flex: 4 !important;
}

.flex5 {
  flex: 5 !important;
}

.flex6 {
  flex: 6 !important;
}

.flex7 {
  flex: 7 !important;
}

.flex8 {
  flex: 8 !important;
}

.flex9 {
  flex: 9 !important;
}

.flex10 {
  flex: 10 !important;
}
