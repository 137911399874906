/*  Global Lists Styles */
@use '@angular/material' as mat;
@use 'terra';

mat-card {
  // Add this class to mat-card-header to hide the empty inner element that gets added
  &.hide-header-text {
    .mat-mdc-card-header-text {
      display: none;
    }
  }
}

.cdk-drag-preview .drag-handle-v2 {
  visibility: visible !important;
}

.ninety-card,
ninety-conversation-detail {
  flex: 1;
  /* used to make the ellipsis stuff work */
  min-width: 0;
  /* Used to make the flexbox items grow to their own height instead of their parent's
   ***Needs to be 0% not just 0***  */
  height: 0%;
  padding: 0 !important;
}

.ninety-card {
  max-width: 1300px;
  &.detail-card {
    max-width: 1000px;
  }
}

.ninety-list {
  min-height: 40px;
  display: block;
  @include terra.radius(small);
  overflow: hidden;

  .mat-list-item {
    padding: 0 10px 0 5px !important;

    // TODO: Remove after v3 is gone
    @media print {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      height: auto !important;
      border-bottom-color: terra.color('border');
    }
  }

  .mdc-list-item {
    padding: 0 10px 0 5px !important;

    // TODO: Remove after v3 is gone
    @media print {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      height: auto !important;
      border-bottom-color: terra.color('border');
    }
  }

  &.no-hover .mat-list-item {
    background-color: transparent;
  }

  &.mat-mdc-selection-list {
    padding-bottom: 0;
  }
}

.list-item,
.cdk-drag-preview {
  border-bottom: solid 1px terra.color('border', 'heavy');
  color: terra.color('text');
  box-sizing: border-box;
  cursor: pointer;
  background: terra.color('white');
  @include terra.text(body);
  display: flex;
  align-items: center;

  &:last-child {
    border: none;
  }

  button {
    color: terra.color('text', 'light');
  }

  ninety-button {
    color: terra.color('text', 'light');
  }

  &.opened,
  &:not(.no-hover):hover {
    background-color: terra.color('neutral-mid', '100') !important;
  }

  &.mdc-list-item.mdc-list-item--selected:focus::before,
  &.mdc-list-item:not(.mdc-list-item--selected):focus::before,
  &.mdc-list-item:hover::before {
    background-color: transparent;
    opacity: 0;
  }

  &.opened::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: terra.color('brand');
  }

  // TODO(mdc-migration) remove after migration
  .mat-list-item-content {
    padding: 0 !important;
    @include terra.text(body);

    .mat-list-text mat-checkbox {
      margin: 0 5px;
    }
  }

  .mdc-list-item__content {
    padding: 0 !important;
    @include terra.text(body);
    display: flex;
    align-items: center;
    height: inherit;
  }

  .list-title,
  .headline-title {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    margin: 0 5px;
    width: 200px;
  }

  .inline-icon.mat-icon {
    display: flex;
    width: 0px;
    align-items: center;
    margin: auto;
  }

  .owner {
    width: 90px;
  }

  .list-due-date,
  .list-item-date {
    white-space: nowrap;
    margin: 0 5px;
    display: flex;
    align-items: center;
    flex: 0 0 38px;
    justify-content: flex-end;

    mat-icon {
      margin-right: 5px;
    }
  }

  .rock-status {
    min-width: 60px;
  }

  .drag-handle {
    visibility: hidden;
    color: terra.color('neutral-mid', '300');
    background: terra.color('white');
    cursor: move;
    @include terra.radius(small);
    display: flex;
    align-items: center;
    width: 1.5rem;
    height: 2.5rem;
    @include terra.elevation(3);
  }

  &:hover .drag-handle {
    visibility: inherit;
  }

  .drag-handle-v2 {
    visibility: hidden;
    color: terra.color('text', 'lightest');
  }

  &:hover .drag-handle-v2:not(.invisible) {
    visibility: inherit;
  }

  &:hover .edit-icon {
    // display: inline-block;
    color: terra.color('neutral-mid', '400');
  }

  &.mat-mdc-list-option .mdc-list-item__content > .list-title {
    margin: 0;
    padding: 0;
  }
}

.cdk-drop-list-dragging,
.drag-disabled {
  .drag-handle {
    visibility: hidden !important;
  }
}

ninety-my-focus,
ninety-future-year,
.goal-list {
  .drag-handle {
    margin: 0 0.35rem;
  }
}

/*  Drag and Drop Stuff */
.cdk-drag-preview {
  box-sizing: border-box;
  @include terra.radius(small);
  @include terra.elevation(5);

  & > div {
    height: 50px;
    display: flex;
    align-items: center;
  }
  .drag-handle {
    visibility: hidden !important;
  }

  .mat-pseudo-checkbox {
    display: none !important;
  }

  display: flex;
  align-items: center;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.ninety-list.cdk-drop-list-dragging .list-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.attachments {
  display: flex;
  align-items: center;
}

.attachment-list {
  .list-item {
    cursor: default;
  }
}

.attachment-title {
  margin-left: 15px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.condensed-list {
  mat-list-item {
    height: 40px;
  }
}

.ninety-list {
  &.selection-mode {
    .list-item {
      cursor: copy;
    }
  }

  .mat-pseudo-checkbox {
    display: none;
  }
}

// Ninety Issues List mat-selection-list
.mat-list-base .mat-list-item.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text,
.mat-list-base .mat-list-item.mat-list-option .mat-list-item-content-reverse .mat-list-text,
.mat-list-base .mat-list-option.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text,
.mat-list-base .mat-list-option.mat-list-option .mat-list-item-content-reverse .mat-list-text,
.cdk-drag-preview .mat-list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1rem;
  position: relative;
  height: inherit;
}

.cdk-drag-preview .mat-list-item-content {
  flex: 1;
}

mat-list-option .mat-list-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1rem;
  position: relative;
  height: inherit;
  width: 100%;
}

mat-list-item.signup-teammate-item {
  .mat-list-item-content {
    padding: 0px !important;
  }
}

.overflow-menu {
  vertical-align: middle;
  visibility: hidden;

  span {
    color: terra.color('text', 'lightest');
  }

  &:hover span {
    color: terra.color('text', 'light') !important;
  }
}

.overflow-menu-container {
  align-items: center;
  height: 24px;
  width: 24px;
  padding-left: 10px;
  box-sizing: content-box;
}

.active-menu-item span {
  visibility: visible !important;
}
ninety-todos .mdc-list-item__content {
  @include terra.text(body);
}
