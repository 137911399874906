.scorecard-action-bar-menu,
.kpi-group-action-menu {
  .mat-mdc-menu-item-text {
    display: flex;
    justify-content: center;

    terra-icon {
      padding-top: 1px;
    }
  }
}

// Prevents horizonal overflow for 'New group' button in the action bar, wraps under filters
.scorecard-action-bar {
  .action-bar {
    flex-wrap: wrap;
    gap: 8px;
  }
}

// Hide quick filter label and separator for small viewports
@media (max-width: 794px) {
  ninety-scorecard-team-selector,
  .scorecard-period-interval-select {
    .pill-inner {
      .pill-title,
      .pill-separator {
        display: none;
      }
    }
  }
}
