@use 'terra';

ninety-my-ninety-page {
  /*
    Due to how gridstack positions things absolutely, placing box-shadows
    on the widgets/cards results in the shadow being hidden.
  */
  .grid-stack ninety-grid-item.grid-stack-item .grid-stack-item-content {
    box-shadow: terra.elevation(3) !important;
    @include terra.radius(small);
  }

  // Todo: Implemenet in rocks table component & remove from here
  // TODO scope this to the right component (don't apply to todos table)
  table {
    tr {
      &.mat-mdc-header-row {
        height: auto;

        th.mat-mdc-header-cell {
          padding: 6px 16px;
          @include terra.text(body-small);

          // Remove extra padding drag-handle + selected bar
          &.drag-handle-th,
          & .mat-column-selected {
            padding: 0 2px;
          }
        }
      }

      // TODO: Add cursor/highlight to header cells with sort headers feature
      &.mat-mdc-row {
        cursor: pointer;

        &:hover {
          background-color: terra.color('neutral-shader', 'lightest');
        }
      }
    }
  }

  /*
    Scoped to my-ninety-page, but will be moved out eventually.

    MatSortHeader is not explicitly tied to MatTables, so the styles are not nested, but that will be the most common
    use case.
  */
  .mat-sort-header {
    color: terra.color('text', 'light');

    .mat-sort-header-arrow {
      color: terra.color('text', 'light');
    }

    &:hover {
      color: terra.color('text');

      .mat-sort-header-arrow {
        color: terra.color('text');
      }
    }

    &-sorted {
      @include terra.text(body-small-bold);
    }
  }

  ninety-card {
    ninety-card-header {
      // Remove the excessive padding from material
      .mat-form-field-wrapper {
        padding-bottom: 0 !important;

        .mat-form-field-infix {
          padding: 0.25rem 0 0.5rem 0 !important;
        }
      }

      // Makes select arrow center aligned with select label
      .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
        transform: unset !important;
      }
    }
  }

  // Override default ninety-card padding when the widget is small
  ninety-team-todos-widget,
  ninety-personal-todos-widget {
    ninety-card.small {
      ninety-card-header {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    th:first-child:nth-last-child(3) ~ th {
      &.mat-column-dueDate {
        padding-left: 55px !important;
      }
    }
    th:first-child:nth-last-child(4) ~ th {
      &.mat-column-dueDate {
        padding-left: 55px !important;
      }
    }
  }
}

ninety-rocks-widget {
  th.mat-column-dueDate {
    padding-inline: 0 !important;
    div {
      justify-content: center;
    }
    .mat-sort-header-content {
      padding-left: 20px;
    }
  }

  ninety-due-date-column {
    display: flex;
    justify-content: flex-end;
  }
}
