// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// this is for the rich text editor
@use '@angular/material' as mat; // use rules must come first now
@use 'terra';

// When providing a label to mat-checkbox using content projection,
// longer labels do not wrap but instead overflow the parent container of the checkbox.
// https://github.com/angular/components/issues/8416
.mat-checkbox-layout {
  white-space: normal !important;
}

@import 'abstracts'; // all variables/mixins/functions

// Reference: https://stackoverflow.com/a/69277954
$custom-typography: mat.define-legacy-typography-config(terra.legacy-get-nunito());
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// TERRATODO (COLOR)
$ng-ninety-primary: mat.define-palette(terra.$legacy-gray-palette, 400, 200, 600);
$ng-ninety-accent: mat.define-palette(terra.$legacy-company-palette, 400, 200, 600);

// The warn palette is optional (defaults to red).
//$ng-ninety-warn: mat.define-palette(mat.$deep-orange-palette, 500);

// Create the theme object (a Sass map containing all of the palettes).
$ng-ninety-theme: mat.define-light-theme(
  (
    color: (
      primary: $ng-ninety-primary,
      accent: $ng-ninety-accent,
    ),
    typography: $custom-typography,
    density: 0,
  )
);

// Include theme styles for _core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

// TODO(mdc-migration) - Remove after migration.
@include mat.all-legacy-component-themes($ng-ninety-theme);
@include mat.all-component-themes($ng-ninety-theme);

// MDC Styles
@import './styles/_mdc-migration';

@import './styles/toastr';

@import 'media-queries';
@import 'utils';
@import 'mixins';
@import 'abstracts-variables';

@import './styles/print';
@import './styles/lists';
@import './styles/momentum';
@import './styles/tables';
@import './styles/forms';
@import './styles/text-editor';
@import './styles/inline-editor';
//@import './styles/check-complete'; // added to the styles array in angular.json for storybook assets resolution
@import './styles/grid';
@import './styles/dialog';
@import './styles/flex-utils.scss';
@import './styles/tooltip.scss';
@import './styles/ag-grid-ninety-theme.scss';

// Base
@import 'base';

// Global Component Styles
@import './styles/components';

// Pages
@import './styles/pages';

/* You can add global styles to this file, and also import other style files */

body {
  &.default-theme {
    #login-page,
    #signup-page,
    #accept-invite-page {
      .logo-wrapper {
        height: clamp(3.25em, 5vmin, 10rem);
      }
    }
  }
}

/* this allows the box shadow to work */
.mat-toolbar {
  background: inherit;
  background-color: terra.color('white') !important;
}

mat-list-item.active,
[mat-list-item].active {
  .mat-list-item-content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
  }
}

mat-checkbox {
  margin: 0 5px;

  .mat-checkbox-inner-container {
    height: 20px;
    width: 20px;
  }
}

.main-sidenav {
  .mat-list-item-content {
    justify-content: flex-start !important;
  }
}

// TODO(mdc-migration) Remove after migration.
body .mat-progress-spinner:not(.suffix-spinner),
body .mat-mdc-progress-spinner:not(.suffix-spinner),
body mat-dialog-container .mat-progress-spinner:not(.suffix-spinner) {
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 1001;
  position: absolute;
}

.main-spinner {
  position: fixed !important;
}

.on-track-icon {
  color: terra.color('green') !important;
  fill: terra.color('green') !important;
}

.off-track-icon {
  color: terra.color('red') !important;
  fill: terra.color('red') !important;
}

.complete-icon {
  color: terra.color('ninety') !important;
  fill: terra.color('ninety') !important;
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.rotate {
  transition: 0.2s;
}

.rotate-left-45 {
  transform: rotate(45deg);
}

.rotate-right-45 {
  transform: rotate(-45deg);
}

.rotate-90 {
  transform: rotateZ(-180deg);
}

.mat-tooltip {
  white-space: pre-line;
  background-color: terra.color('neutral-heavy', '500');
  //Used for most single-line tooltips across the app
  &.ninety-tooltip {
    @include terra.text(body);
  }

  //Used for tooltips with a lot of text, such as Rocks (Departmental Rocks), Scorecard, GWC, etc.
  &.multiline-tooltip {
    @include terra.text(body-small);
  }

  //Only used once, something related to To-Dos not completed on time, not 100% clear
  .tooltip-text-center {
    text-align: center;
  }

  //Used for GWC in Mettings and Feedback, to force the tooltip to have a min width
  .wider-tooltip {
    min-width: 350px;
  }
}

.inline-link-btn {
  color: terra.color('brand');
  display: inline;
  background: none !important;
  border: none;
  padding: 0 !important;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.tooltip {
  .tooltip-values {
    @include terra.text(title-small-light);
  }
}

.ninety-chip,
.ninety-accent-chip {
  cursor: pointer;
  margin: 4px;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 7px 12px;
  @include terra.radius(large);
  align-items: center;
  min-height: 32px;
  height: 1px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
  @include terra.text(body);
}

.ninety-chip {
  background-color: terra.color('border');
  color: terra.color('text');
}

.ninety-accent-chip {
  color: terra.color('text-invert');
}

.ninety-counter {
  padding: 7px 5px;
  margin: 0px 7px;
  @include terra.radius(huge);
  background-color: terra.color('neutral-light', '400');
  color: terra.color('text');
}

.small-icon {
  transform: scale(0.75);
  cursor: pointer;
}

mat-sidenav {
  @include xs {
    display: none;
    margin-left: -60px;
  }
}

mat-sidenav-content {
  @include lt-lg {
    margin-left: 0 !important;
  }
}

.small-chips {
  mat-chip {
    padding: 4px 8px;
    min-height: 28px;
    @include terra.text(body-small);
  }
}

.xs-chips {
  mat-chip {
    padding: 4px 8px;
    min-height: 22px;
    @include terra.text(body-tiny);
  }
}

.mat-select-panel {
  max-width: inherit;
}

.avatar-image,
.user-initials,
.no-user {
  display: block;
  height: 35px;
  width: 35px;
  @include terra.radius(huge);
}

.user-initials {
  background-color: terra.color('neutral-heavy', '100');
  color: terra.color('white');
  @include terra.text(body);
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-image {
  background: no-repeat center center;
  background-size: cover;
  @media screen {
    color: transparent;
  }
}

.user-name {
  flex: 1;
  margin: 0 10px;

  span {
    color: terra.color('orange');
  }
}

.no-user {
  display: flex;
  align-items: center;
  justify-content: center;

  & > mat-icon {
    transform: scale(1.8);
    color: terra.color('neutral-heavy', '100');
  }
}

.autocomplete-user-option {
  display: flex;
  align-items: center;
}

.innerHTML-description {
  margin: 0;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.vertical-divider {
  height: 70%;
  margin: 0 15px;
}

.meeting-content {
  .container {
    padding: 5px;
  }
}

.conclude-container {
  ninety-todos .container {
    padding: 0;

    .ninety-card {
      margin: 0 0 5px;
    }
  }

  .headlines-content .container {
    padding-right: 0;
    padding-left: 0;

    .headlines-card {
      margin: 5px 0 5px 0;
    }
  }
}

.mat-icon.due-today-yellow {
  color: terra.color('yellow');
}

.meetings-list-content,
.headlines-content,
.partner-content,
.people-analyzer-content {
  display: flex;
  flex-direction: row;

  .container {
    flex-wrap: wrap;
    flex: 1;
    height: 0%;
    margin-bottom: 75px;
  }

  .ninety-card {
    flex: 1 1 1300px;
    flex-wrap: wrap;

    mat-card-title {
      @include terra.text(body-large);
      margin-bottom: 0;
    }
  }

  mat-card {
    flex: 1;
    // max-height: 75vh;
    // overflow: hidden;
    // overflow-y: auto;

    mat-card-footer {
      margin: 0;

      .mat-paginator {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .title-card {
    display: flex;
    justify-content: flex-end;

    button {
      margin: 10px !important;
    }
  }

  .header-btns {
    height: 32px;
    padding: 15px 10px 0 0;
    margin-bottom: 3px;
    align-items: initial;

    mat-card-subtitle {
      margin-top: 0;
    }

    .title-btn,
    .team-title,
    .messages-title-btn {
      margin-left: 35px;
    }

    button {
      color: terra.color('text', 'light');
      padding: 0;
      min-width: 0;
    }
  }

  .team-name {
    @include ellipsis;
  }

  .cascaded,
  .meeting-average,
  .rating-title {
    width: 90px;
    display: flex;
    justify-content: center;
  }

  .owner {
    width: 75px;
    display: flex;
    justify-content: center;
  }

  .cascaded {
    width: auto;
  }

  .ninety-list {
    .team-name {
      display: flex;
      align-items: center;
      margin-left: 30px;
    }

    .headline-title {
      margin: 0 10px 0 30px;
    }

    mat-chip {
      color: terra.color('white') !important;
    }
  }
}

.meetings-list-content,
.partner-content {
  .headlines-content .container {
    padding: 0;
  }
}

.universal-create-dialog-container {
  width: 756px;
  @media screen and (max-width: 746px) {
    width: calc(100vw - 10px);
  }
  @media (max-height: 500px) {
    height: 100vh;
    padding: 20px 0;
  }
}

.add-person-container {
  .menu-btn {
    @include terra.elevation(0);
  }
}

button.ninety-edit-btn {
  right: 4px;
  color: terra.color('text', 'light');
}

.vto-cascade-toggle,
.completed-conversations-toggle {
  .mdc-label {
    @include terra.text(body-large);
  }
}

.editing-list {
  margin: 10px -16px 0;

  mat-list-item {
    height: auto !important;
    border-bottom: none;

    &.mat-list-item {
      padding: 0 !important;
    }
  }
}

.header-input {
  margin-bottom: -20px;
}

.checklist-header {
  margin-top: 30px;
  display: flex;
  align-items: center;

  // src/app/_shared/components/process-card usage
  h4 {
    margin: 0;
  }
}

.small-chips .ninety-chip.slt-chip {
  margin: 0 10px;
}

.weakest {
  .mat-progress-bar-fill::after {
    background-color: terra.color('red');
  }
}

.weak {
  .mat-progress-bar-fill::after {
    background-color: terra.color('orange');
  }
}

.moderate {
  .mat-progress-bar-fill::after {
    background-color: terra.color('yellow');
  }
}

.strong {
  .mat-progress-bar-fill::after {
    background-color: terra.color('yellow');
  }
}

.strongest {
  .mat-progress-bar-fill::after {
    background-color: terra.color('green');
  }
}

.disabled {
  &,
  & mat-icon {
    color: terra.color('neutral-mid', '400') !important;
    pointer-events: none;
  }
}

#add-invite-modal {
  padding: 0 24px;
  // max-height: 80vh;
  height: 100%;
  overflow-x: clip;
}

#add-teammate-modal {
  padding: 0px 16px;
}

.max-height {
  max-height: 175px !important;
}

.chart-card {
  mat-card-header {
    flex-wrap: wrap;
  }

  mat-card-content {
    display: flex;
    justify-content: center;
  }

  mat-form-field {
    margin: 5px;
  }

  .advanced-pie-legend .legend-items-container .legend-items {
    overflow: initial !important;

    .legend-item .item-label {
      margin-top: 0;
    }
  }

  .refresh-btn {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .todo-footnote {
    margin: 10px 0px 0px;
    @include terra.text(body-small);
    text-align: center;
  }

  .dropdowns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .pie-grid .percent-label {
    @include terra.text(body-large-bold);
  }
}

ninety-issues-list {
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding-left: 5px;
  }
}

ninety-directory-add-invite-dialog {
  #add-invite .mat-mdc-dialog-container.mdc-dialog .mat-mdc-dialog-content {
    padding: 0;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    background-color: terra.color('brand', 'light') !important;
  }

  // add user button icon-text alignment fix
  .mdc-button .mdc-button__label {
    gap: 4px;
    display: flex;
    position: relative;
  }

  .mat-mdc-dialog-content {
    max-height: 30vh;
  }

  .mdc-text-field--filled:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: terra.color('brand') !important;

    &:hover {
      border-bottom-width: 5px;
    }
  }

  .mat-form-field-infix {
    border-top: 0;
    padding-bottom: 0;
  }

  mat-form-field {
    @include mat.form-field-density(-5);
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding: 0px;
    }
  }

  .mat-mdc-text-field-wrapper {
    padding-left: 10px;
    padding-right: 12px;
  }
}

.review-form-table .mat-form-field-infix {
  width: auto;
  padding: 0;
}

ninety-directory-modify-user-dialog {
  .mat-form-field-infix {
    border-top-width: 0;
  }

  .mat-form-field-underline {
    background-color: rgb(var(--secondaryAccent)) !important;
  }

  #email-form-field .mat-form-field-wrapper {
    margin-bottom: -0.55em;
  }

  .mat-select-arrow-wrapper {
    position: relative;
    right: 1.25em;
    top: 0.5em;
  }

  .modify-user-form {
    mat-form-field.full-width-input {
      margin-left: 0;
    }

    ninety-teams-select .mat-form-field-wrapper {
      margin-bottom: 1em;
    }
  }

  ninety-role-select .mat-form-field-label-wrapper {
    transform: translateY(-0.35em);
  }
}

ninety-role-select {
  .mat-form-field-infix {
    border-top-width: 0;
    padding: 0;
  }

  .hide-border .mat-form-field-underline {
    display: none;
  }

  .hide-border .mat-form-field-wrapper {
    padding-bottom: 0.2em;
  }
}

ninety-role-select.error {
  .mat-mdc-select-placeholder {
    color: terra.color('red');
  }
}

ninety-directory-add-invite-mobile-modal {
  .long-form-field .mat-form-field-wrapper {
    padding-bottom: 0.3em;
  }

  ninety-role-select {
    .mat-form-field {
      width: 100%;
    }

    .mat-select-arrow-wrapper {
      position: relative;
      right: 1em;
    }
  }

  ninety-teams-select .mat-form-field-infix {
    border-top-width: 0.35em;
  }

  fieldset {
    .mat-select-min-line,
    .mat-select-placeholder,
    legend.fieldset-legend {
      transform: color 0.25s;
    }
  }

  fieldset.error {
    .mat-select-min-line,
    .mat-select-placeholder,
    div.selected-teams span,
    legend.fieldset-legend {
      color: terra.color('red');
    }
  }
}

$input-spacing: 1em;

ninety-directory-add-invite-user {
  .role-error {
    @include terra.text(body-small, true);
  }

  .mat-mdc-select {
    @include terra.text('body-large', true);
  }

  .mat-mdc-select-value {
    text-indent: 0.2em;
  }

  button.mat-menu-trigger {
    border-bottom-color: terra.color('brand') !important;

    div.button-text-wrapper {
      margin-left: 0.4em;
    }
  }

  ninety-user-avatar {
    span.avatar-image {
      position: relative;
    }
  }

  ninety-teams-select {
    mat-error {
      position: relative;
      left: +0.588em;
    }
  }

  ninety-role-select {
    .mat-form-field {
      width: 100%;
      margin-left: -#{$input-spacing};
    }

    .mat-select-arrow-wrapper {
      position: relative;
      right: 1.5em;
    }
  }

  .mat-focused .mat-form-field-label {
    color: terra.color('brand') !important;
  }

  .mat-form-field-underline {
    background-color: terra.color('brand') !important;
  }

  .mat-form-field-ripple {
    background-color: terra.color('brand') !important;
  }
}

.simple-card {
  .container {
    margin-bottom: 50px;
    @media print {
      margin-bottom: 0px;
    }
  }

  mat-card {
    flex: 1;

    mat-card-title {
      @include terra.text(body-large);
    }
  }
}

.meeting-content {
  ninety-meeting-rocks-issues ninety-rocks .container {
    margin-top: 65px;
  }
}

.directory-card {
  .mat-form-field-underline {
    display: none;
  }

  .teams-select {
    margin-right: 15px;

    button {
      border-bottom: none;
      color: initial;
      @include terra.text(body);

      &:hover,
      &:active,
      &:focus {
        border-bottom: none;
      }
    }

    .mat-form-field-wrapper {
      padding: 0;
    }
  }
}

.milestone-todos-toggle,
.private-accountability-chart-toggle {
  min-width: 250px;
  .mdc-label {
    white-space: initial;
    overflow: visible;
    text-overflow: initial;
  }
}

.confirm-dialog-container,
.medium-dialog {
  @media (min-width: 599px) {
    width: 550px;
  }
}

.medium-plus-dialog {
  @media (min-width: 700px) {
    width: 650px;
  }
}

.create-company-dialog {
  @media (min-width: 599px) {
    width: 550px;
  }
  @media (max-width: 599px) {
    width: 100%;
  }
}

ninety-meeting-notes {
  display: none;
  // Needs to be so high to cover froala details in page/detail
  z-index: 999;

  @include gt-xs {
    display: initial;
  }

  &.nav-expanded {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100vw - 252px);
    @include terra.elevation(3);
  }

  ninety-text-editor-froala {
    .fr-element.fr-view {
      max-height: 25vh;
    }
  }
}

.tools-hub {
  .mdc-label {
    display: flex;
    align-items: center;

    mat-icon {
      margin-right: 4px;
    }
  }
}

.mat-expansion-panel-header {
  padding: 1.5em 1em !important;
  height: auto;
  box-sizing: content-box;

  &.mat-expanded {
    height: auto;
  }
}

mat-list {
  box-sizing: content-box;
}

.prior-quarter-panel {
  .mat-expansion-panel-body {
    padding: 0;
  }

  mat-card {
    margin: 0;
  }

  .mat-expansion-panel-header-title {
    @include terra.text(body-large);
  }
}

.section-details .ql-container {
  height: 50%;
}

.add-section .mat-list-item-content {
  justify-content: center;
}

#my-account__page {
  mat-form-field,
  .mat-form-field,
  .mat-form-field-infix {
    width: 100%;
  }

  .person-metadata__entry {
    &.editing:first-of-type {
      mat-form-field {
        margin-right: 30px;
      }
    }

    &.strength-finder .strength-entry {
      mat-form-field {
        height: 38px;
      }

      mat-form-field-infix {
        padding-bottom: 0;
      }
    }
  }

  //need to override the generic mat-form-field width: 100%;
  .primary-email {
    display: inline-block;
    width: 100%;
    @include gt-xs() {
      width: 350px;
    }
  }
}

#upgrade-password-dialog__container {
  mat-form-field,
  .mat-form-field,
  .mat-form-field-infix {
    width: 100%;
  }
}

#henryx-verification__container {
  mat-form-field,
  .mat-form-field,
  .mat-form-field-infix,
  mat-select {
    width: 240px;
  }
}

.email-notifs {
  .mat-select-value-text {
    margin-right: 8px;
  }
}

.default-measurable-img {
  height: 30px;
  width: 30px;
}

.cdk-overlay-pane:not(.measurables-dialog-container) {
  max-height: calc(95% - 53px);
}

.cdk-overlay-pane.add-invite-modal-container {
  @include lt-sm {
    max-height: 100vh;
  }
}

@include lt-sm {
  .intercom-lightweight-app {
    z-index: 999 !important;
  }

  #intercom-container {
    z-index: 999 !important;
    position: relative;
  }

  .cdk-overlay-pane.measurables-dialog-container {
    width: 100vw;
    max-width: 100vw !important;

    & .mat-dialog-container {
      padding: 5px 5px 25px 5px;
    }

    mat-paginator .mat-paginator-range-actions {
      width: 100%;
      justify-content: space-between;

      .mat-paginator-range-label {
        margin: 0 1em 0 0.5em;
      }
    }
  }
  .full-sm {
    width: 100%;
  }
}

.cdk-overlay-pane.future-goal-detail-dialog-container {
  max-width: none !important;
  max-height: none;
  width: 100%;
  height: 100%;

  .mat-dialog-container {
    padding: 0px;

    .mat-card {
      margin: 0px;
    }
  }

  @media screen and (min-width: 960px) {
    max-width: 80vw !important;
    max-height: 95vh;
  }
}

/* Defined globally to avoid ::ng-deep */
ninety-conversation-meeting,
ninety-conversation-meeting-form {
  .seat-rating {
    .mat-select-trigger {
      width: 50px;
      border-bottom: 1px solid terra.color('orange');
    }
  }

  .core-values {
    .mat-list-item-content {
      flex-wrap: wrap;
    }

    .mat-select-trigger {
      width: 50px;
      border-bottom: 1px solid terra.color('orange');
    }
  }

  .role-headers {
    .mat-list-item-content {
      display: grid !important;
      grid-template-columns: 33% 33% 33%;
    }
  }

  .cards-container {
    .mat-card-header-text {
      margin: 0;
      flex: 1;
    }
  }

  .roles-list-item,
  .leadership-assessment-list {
    .mat-list-item-content {
      display: grid !important;
      grid-template-columns: 100%;

      @media only screen and (min-width: 768px) {
        grid-template-columns: 33% 33% 33%;
      }
    }

    .mat-select-trigger {
      width: 50px;
      border-bottom: 1px solid terra.color('orange');
    }
  }

  @media (max-width: 599px) {
    .cards-container {
      .mat-form-field-infix {
        width: auto;
      }
    }
  }
}

mat-drawer.feedback-convo-mat-drawer {
  > div.mat-drawer-inner-container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
}

// Global class for list view toggle buttons
.list-view-toggle {
  @include terra.text(headline-light);

  > .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0 4px;
    line-height: 30px;
  }
}

.tippy-box[data-theme~='warning'] {
  background-color: terra.color('red');
  fill: terra.color('red');
  color: terra.color('white');

  &[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: terra.color('red');
  }

  &[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: terra.color('red');
  }

  &[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: terra.color('red');
  }

  &[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: terra.color('red');
  }
}

.create-select-options {
  .headline-team-select__container,
  .regular-team-select__container {
    mat-form-field,
    .mat-form-field,
    .mat-form-field-infix {
      border-top: unset;
    }
  }
}

.scorecard-container,
.trailing-cards-wrapper,
ninety-my-scorecard {
  .status-indicator {
    &.on-track {
      background-color: terra.color('green');
    }

    &.at-risk {
      background-color: terra.color('yellow');
    }

    &.off-track {
      background-color: terra.color('red');
    }
  }
}

.forecasting-card-wrp {
  .mat-card-header-text {
    margin-left: 0;
  }

  .forecasting-table-wrp {
    .wsnr {
      white-space: nowrap;
    }

    .logic-operator {
      display: block;
    }

    .mat-form-field-infix {
      width: auto;
      padding: 0;
    }
  }
}

.issues-on-other-teams,
.customization-accordion {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

.send-issue-to-team-btn {
  mat-select {
    display: inline;

    .mat-select-arrow-wrapper {
      display: none;
    }
  }
}

.logo-upload-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em;
  position: relative;
  line-height: 0;
  outline: none;
  min-height: 150px;
  box-sizing: content-box;

  img {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  &:not(.disabled) {
    terra-icon {
      color: terra.color('text-invert');
      display: block;
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 12px);
      z-index: -2;
    }

    &:before {
      content: '';
      border: 1px solid terra.color('border');
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    &:hover,
    &.no-logo {
      cursor: pointer;
      background-color: terra.color('border');

      terra-icon {
        z-index: 2;
      }

      &:before {
        background-color: terra.color('text', 'light');
        z-index: 2;
      }
    }
  }
}

.color-inputs {
  .mat-form-field-infix {
    text-align: center;
  }
}

// Using 2 selectors increases specificity, allowing us to override
.company-menu.mat-menu-panel {
  width: 320px;
  max-width: 320px;
}

.issues-other-teams {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

// froala rich text editor
.fr-box {
  display: block !important; // don't ever need this to be anything else
  width: 100%;
  //z-index: 999;
}

.fr-quick-insert {
  margin-left: 30px;
}

.fr-popup {
  z-index: 1000 !important;
}

.meeting-notes {
  .fr-wrapper {
    max-height: calc(40vh);
    overflow: auto;
  }
}

.mat-drawer-container {
  background-color: unset;
}

.comments-container {
  background-color: terra.color('neutral-light', '300');
  border-top: 1px solid terra.color('border');
  padding: 16px;
  margin: 0 -16px -16px -16px;

  mat-form-field {
    width: 100%;
  }

  .list-item {
    cursor: auto;
    background: inherit;
  }
}

.comment-list-item {
  flex-direction: column;
  width: 100%;

  .mat-input-element:disabled {
    color: inherit;
  }

  .user-input {
    display: flex;
    align-items: center;
  }

  ninety-user-avatar {
    margin-right: 15px;
  }

  .comment-date {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
  }
}

.new-comment {
  margin-top: 15px;
}

.customer-menu.mat-menu-panel {
  max-width: unset;

  [mat-menu-item] {
    display: flex;
    justify-content: space-between;
  }
}

// todo - figure out why I need to add important here
.detail-close-tab {
  min-width: 53px !important;
  padding: 0 !important;
}

.mat-mdc-chip {
  width: max-content;
  @include terra.radius(huge);
  padding: 4px 12px 4px;
  margin: 4px;
  @include terra.text(body);
}

.mat-mdc-chip-option.mat-mdc-standard-chip {
  color: terra.color('white') !important;
  background-color: terra.color('shadow', 'heaviest');

  &.good {
    background-color: terra.color('green') !important;
  }

  &.bad {
    background-color: terra.color('red') !important;
  }

  &.primary {
    background-color: terra.color('brand');
  }

  .mat-mdc-chip-action-label {
    color: terra.color('white') !important;
  }
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: 1px solid terra.color('border');
}

.smart-formula-number-input {
  max-height: 40px;

  .mat-form-field-infix {
    width: auto;
    padding: 0;
  }
}

.settings-wrp {
  .mat-mdc-slide-toggle {
    height: auto;
    .mdc-label {
      white-space: initial;
      overflow: visible;
      text-overflow: initial;
      line-height: normal;
    }
  }

  .mat-form-field-wrapper {
    margin-bottom: -1.25em;
  }
}

input.is-invalid-number {
  border: 2px solid terra.color('red') !important;
}

.cascade-toggle,
.mat-card > :first-child.cascade-toggle {
  margin: 10px 16px;
}

div.mat-select-panel {
  max-height: calc(8 * 3rem); // temp set to 8, will work on it here https://traxion.atlassian.net/browse/DEV-10562
  overflow-y: auto;
  &.team-select-panel {
    max-height: 400px;
    margin-top: 35px;
    mat-option {
      height: 36px;
    }
  }
}

.table-header-btn {
  color: terra.color('text', 'light');
  text-transform: none;
  text-align: left;
  min-width: auto;
  margin: 0 !important;

  .table-header-btn-text {
    margin-right: -2px;
  }

  md-icon {
    color: terra.color('text', 'light');

    svg {
      width: 15px;
    }

    md-icon {
      color: terra.color('text', 'light');
    }

    &:not([disabled]):hover,
    &.md-focused,
    &.md-ink-ripple {
      background-color: inherit;
    }
  }

  .left-space-60 {
    margin-left: 60px !important;
  }

  .table-header-btn-text {
    margin-right: -2px;
  }

  md-icon {
    color: terra.color('text', 'light');

    svg {
      width: 15px;
    }
  }

  &:not([disabled]):hover,
  &.md-focused,
  &.md-ink-ripple {
    background-color: inherit;
  }
}

.mat-flat-button {
  @include terra.radius(medium);

  &.md-tiny {
    @include terra.text(body-tiny);
    min-height: 20px;
    line-height: 20px;
  }

  &.md-dense {
    @include terra.text(body);
    line-height: 32px;
    min-height: 32px;
  }

  .md-ripple-container {
    @include terra.radius(small);
  }

  &.inline-icon-button:not(.md-icon-button) {
    md-icon {
      margin-top: -2px;
      margin-left: -8px;
    }
  }
}

mat-icon {
  &.mat-large {
    height: 36px !important;
    width: 36px !important;
    min-height: 36px !important;
    max-width: 36px !important;
  }

  &.mmat-dense {
    height: 20px !important;
    width: 20px !important;
    min-height: 20px !important;
    max-width: 20px !important;
  }

  &.mmat-tiny {
    height: 16px !important;
    width: 16px !important;
    min-height: 16px !important;
    max-width: 16px !important;
  }
}

#main-sidebar {
  .mat-drawer-inner-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;

    &:hover {
      overflow-y: auto;
    }
  }

  mat-list-item,
  [mat-list-item] {
    overflow-x: hidden;
    width: 230px;
    height: 45px;
    color: terra.color('neutral-heavy', '200');
    @include terra.text(body-small);
    text-transform: uppercase;

    &.active {
      color: terra.color('brand') !important;
    }

    mat-icon {
      margin-right: 15px;
      height: 24px;
      width: 24px;
    }
  }
}

#meeting-conclude-page {
  margin: 5px;

  .recap-checkbox-group {
    .mat-checkbox-layout {
      white-space: normal;
    }
  }
}

ninety-item-card {
  h2,
  .item-card-title {
    @include terra.text(title);
    margin: 0;
  }
}

.count-icons-wrapper {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.partner-hub-container,
.meeting-settings {
  .mat-expansion-panel-header {
    @include terra.text(body-large-bold);
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  mat-card-title {
    @include terra.text(title);
  }

  ninety-vto-grid {
    height: calc(100vh - 160px);
    overflow: auto; // By default, the overflow is hidden meaning that the VTO in my-framework can't be scrolled.
  }

  .vto-settings {
    .vto-tab-group {
      .mat-toolbar {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1000;
        background: terra.color('white');
        height: 64px;
      }
    }

    &.no-edit {
      .ninety-edit-btn {
        display: none;
      }
    }
  }
}

.client-companies,
.agenda-settings {
  .mat-toolbar-row,
  .mat-toolbar-single-row {
    // height: auto;
    padding: 0 10px 0 5px;

    .mat-card-subtitle {
      margin-bottom: 0;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.lone-header {
  @include terra.text(body-large);
  margin-top: 40px;
}

.partner-link,
.main-referral-link,
.client-companies,
.partner-hub-container,
.add-person-form-field,
.form-list,
.suffix-btn-link,
.agenda-settings,
.settings-wrapper,
.fields,
.outline-field {
  .mat-list-item.link-item {
    height: auto;
    margin-bottom: 10px;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 5px 0 10px;
  }

  .mat-form-field-label-wrapper {
    top: -1.2em;
  }
}

.outline-field.mat-form-field-appearance-outline {
  width: 100%;
  .mat-form-field-infix {
    padding: 5px 0 10px;
  }
}

.partner-link,
.main-referral-link,
.add-person-form-field,
.client-companies,
.suffix-btn-link,
.outline-field {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 0;
  }

  .mat-select-arrow-wrapper {
    height: unset;
  }
}

.partner-links,
.add-person-form-field,
.form-list,
.suffix-btn-link,
.fields,
.outline-field {
  .mat-form-field-wrapper {
    padding: 0;
  }
}

.partner-links,
.fields {
  mat-list-item.link-item .mat-list-item-content {
    padding: 0;
  }
}

.custom-language {
  .ninety-card {
    max-width: none;
  }
}

.sticky-tabs .mat-tab-header,
.sticky-toolbar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
  background: terra.color('white');

  .right-toolbar-tab {
    right: 0;
    opacity: unset;
    position: absolute;
  }
}

.sticky-toolbar {
  border-bottom: 1px solid terra.color('border');
}

.drag {
  cursor: move;
}

.language-panels {
  mat-card-header.mat-card-header {
    padding: 0;
  }

  .mat-card-header-text {
    margin: 0;
  }
}

.mat-stroked-button,
.mat-flat-button {
  //target the first icon, sometimes we have icons on both sides
  mat-icon:first-child {
    margin-right: 6px;
  }
}

// Flat button styling as per new design system
.mat-flat-button {
  // Primary Button styling
  &.button-primary {
    color: terra.color('white');
    background-color: terra.color('brand');
    @include terra.radius(medium);

    &:hover:not([disabled]) {
      background-color: terra.color('brand');
    }
  }

  // Secondary Button styling
  &.button-secondary {
    color: terra.color('brand');
    background-color: terra.color('white');
    border: 1px solid terra.color('border');
    @include terra.radius(medium);

    &:hover {
      background-color: terra.color('neutral-light', '200');
      border-color: terra.color('border', 'heavy');
    }

    &:disabled {
      background-color: terra.color('neutral-shader');
      border-color: terra.color('border');
    }
  }

  // Tertiary Button styling
  &.button-tertiary {
    color: terra.color('text', 'light');
    background-color: terra.color('white');
    border: 1px solid terra.color('border');
    @include terra.radius(medium);

    &:hover {
      background-color: terra.color('neutral-light', '200');
      border-color: terra.color('border', 'heavy');
    }

    &:disabled {
      background-color: terra.color('neutral-shader');
      border-color: terra.color('border');
    }
  }
}

.agenda-settings .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0;
}

.sticky-tabs,
.vto-tab-group,
#meetings-toolbar {
  .right-toolbar-tab {
    right: 0;
    opacity: unset;
    position: absolute;
  }
}

.settings-wrapper {
  .mat-drawer-inner-container {
    overflow-x: hidden;
  }

  .container {
    flex-direction: column;
  }

  .mat-card-header .mat-card-title {
    margin-bottom: 25px;
  }

  .mat-select-arrow-wrapper {
    height: unset;
  }

  .user-settings-toolbar {
    position: sticky;
    position: -webkit-sticky; /* For macOS/iOS Safari */
    top: 0; /* Sets the sticky toolbar to be on top */
    z-index: 1000; /* Ensure that your app's content doesn't overlap the toolbar */
    background: terra.color('white');
    height: 64px;

    button {
      margin: 0 10px;
    }
  }

  .daily-email-list {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  .phone-num-form {
    input {
      height: 40px;
    }
  }

  .sticky-toolbar {
    height: 48px;

    .save-btn {
      margin-right: 8px;
    }
  }

  mat-card-content {
    padding: 0 32px 16px;
  }

  .contact-list,
  .strength-list,
  .contact-list {
    padding: 0;

    .mat-list-item {
      margin: 0;
      padding: 0;

      .mat-list-item-content {
        padding: 0;
      }

      mat-label {
        @include terra.text(body);
        margin-bottom: 6px;
      }
    }
  }

  .contact-list,
  .contact-list {
    .mat-list-item {
      height: unset;

      &.first {
        .mat-list-item-content {
          align-items: end;

          .delete-btn {
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  .strength-list {
    .mat-list-item {
      height: 55px;
    }
  }

  .fields-dev {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }

  .field-wrapper {
    flex: 1;
    margin: 8px;
    .outline-field {
      width: 100%;
    }
  }
}

.mat-card.mastery {
  .mat-toolbar.mat-toolbar-single-row {
    height: auto;
  }
  .mat-toolbar-row,
  .mat-toolbar-single-row {
    padding: 0 0 20px 0;
  }
}

.agenda-settings {
  .meeting-select {
    padding-right: 0;
    .mat-form-field-outline-end {
      @include terra.radius(none);
    }
  }
  .mat-select-arrow-wrapper {
    height: unset;
  }
}

@include lt-md() {
  .agenda-list {
    .mat-list-item-content {
      flex-wrap: wrap;
    }
  }
  .agenda-settings {
    .ninety-list .mat-list-item {
      padding: 10px !important; // really want to audit these list styles and everything
    }
  }
}

.sub-text {
  @include terra.text(body);
  color: terra.color('text', 'light');
}

.ninety-button-group {
  display: flex;
  button {
    @include terra.radius(small);
  }
}

.brand-promises {
  .non-input-inline-title {
    display: flex;
    align-items: center;
  }
}

.meeting-conversation-role-ratings mat-card.large {
  max-width: 1380px !important;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.alert-red {
  color: terra.color('red') !important;
}

button.mat-mdc-icon-button[disabled] {
  opacity: 0.38;
}

ninety-meeting-detail-headlines .widget-skeleton {
  padding: 0 !important;
}

/////////////////////////////////////////////////
/////////////////////////////////////////////////
// Apply branding colors after everything else.
@import './styles/branding.scss';
/////////////////////////////////////////////////
/////////////////////////////////////////////////

.cdk-overlay-container .cdk-overlay-pane:has(.learning-subject-panel-class) {
  min-width: 200px !important;
}

.learning-panel-class .mat-mdc-select-trigger {
  max-height: 24px !important;
  display: flex;
  align-items: center;
}

.cdk-overlay-pane div.custom-menu-position {
  max-height: 472px;
  overflow-y: auto;
}
