@use 'terra';
// use ngClass to optionally turn on/off...must use component property
.table-sticky-headers {
  max-height: calc(100vh - 380px);
  overflow: auto;
  //remove height constrain if device is in portrait and less than 3 measurables are shown
  @media only screen and (orientation: landscape) and (max-height: 550px) {
    max-height: 100vh;
  }
}
.selected-bar {
  width: 4px;
  min-height: 50px;
}

.ninety-table {
  width: 100%;

  .mat-row {
    &.selected,
    &:hover {
      background-color: terra.color('neutral-mid', '100');
    }
  }

  .drag-handle {
    visibility: hidden;
    color: terra.color('neutral-mid', '300');
    cursor: move;
  }

  tr.mat-header-row {
    height: 25px;
  }

  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type,
  th.mat-header-cell:last-of-type {
    padding-right: 0;
  }
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type,
  th.mat-header-cell:first-of-type {
    padding-left: 0;
  }

  .cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  tr:hover {
    .drag-handle,
    .divider-btn {
      visibility: inherit;
    }
  }

  .cdk-drag-preview {
    padding: 0;
  }

  th.mat-header-cell {
    padding: 0 2px;
  }
}

.ninety-cell {
  border-right: 1px solid terra.color('border');
  display: flex;
  align-items: center;
  padding: 0 3px;
  min-width: 60px;
  min-height: 40px;
  justify-content: flex-end;
  box-sizing: content-box;
}

.crossed {
  background-image: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      terra.color('neutral-mid', '200') 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      terra.color('neutral-mid', '200') 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
  background-size: 90% 90%;
  background-repeat: no-repeat;
  background-position: center;
}

.ninety-header:not(:last-of-type) {
  border-right: 1px solid terra.color('border');
}

.td-drag-handle {
  max-width: 30px;
}

.drag-handle-th,
.drag-handle-td {
  width: 20px;
  text-align: center;
}

// Add border styling through the use of ::after, instead of the normal CSS border.
//  $edge-gap: the padding between the edge of the table and the first/last column
@mixin include-row-border($edge-gap, $bottom: 0) {
  $total-gap: $edge-gap * 2;

  position: absolute;
  right: $edge-gap;
  left: $edge-gap;
  bottom: $bottom;

  width: calc(100% - $total-gap);
  height: 1px;

  background-color: terra.color('border', 'light');
  content: '';
}
