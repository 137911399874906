@use 'terra';

ninety-meeting-conclude {
  .mdc-list-item__primary-text {
    width: 100%;
  }

  .mdc-text-field--disabled {
    background-color: none;
    border-bottom: dotted terra.color('border', 'light');
  }
}

ninety-meetings-v2 {
  .mat-mdc-tab-nav-bar {
    background: none !important;
  }
}

.meetings-v2-card-wrp .empty-list-view {
  padding-top: 0 !important;
}
