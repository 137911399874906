@use 'terra';
@import 'apps/ng-ninety/src/styles/pages/utils';

@mixin vto-core-values-list-style() {
  .mdc-list-item__content {
    .mdc-list-item__primary-text {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }
}

@mixin vto-swot-list-style {
  display: flex;
  width: 100%;
  align-items: center;
  mat-form-field {
    flex-grow: 1;
    margin: 0 8px 0 11px;
  }
}

.vto-page {
  mat-tab-header {
    height: 56px;
  }

  mat-tab-body {
    @include set-default-grid-page-padding();
  }

  .mat-tab-label-container {
    align-items: flex-end;
  }

  ninety-vto-custom-section {
    mat-card-content {
      // Must be full height so iFrame fills available space.
      // See src/app/vto/sections/vto-custom-section/vto-custom-section.component.scss for more iFrame CSS.
      height: 100%;
    }
  }

  // Not using custom vto
  ninety-eos-traction {
    ninety-issues-list-new {
      section.container {
        .issues-content {
          // Ensures that the issues-list container is bounded by its surrounding container. Otherwise, it will overflow
          // its parents and not maintain its expected 33% width in certain viewports
          width: 100%;
        }
      }
    }
  }

  // Using custom vto
  ninety-issues-list-new {
    mat-card mat-card-title {
      padding: 16px !important;
    }

    .issues-content {
      // Constrain to grid-item width
      max-width: 100%;

      & > mat-card {
        @include screen-gt(1300px) {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  ninety-core-values {
    mat-list.core-values-editing-list {
      @include vto-core-values-list-style();
    }
  }
}

.core-values-editing-list-item.cdk-drag-preview {
  @include vto-core-values-list-style();
}

ninety-vto-cascadable-section {
  .fr-view p {
    // Remove default Froala padding as its already added by mat-card-content by default
    margin-top: 0;
    margin-bottom: 0;
  }

  // Material insists on adding this as a wrapper dev to an input when its in a mat-header. This ensures our input
  // fills all available space.
  .mat-mdc-card-header-text {
    display: flex;
    flex: 1;
  }
}

// When we don't use either errors or descriptions, remove the space material reserves for them
@mixin remove-form-field-subscript() {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

ninety-marketing-strategy {
  mat-card-content {
    @include remove-form-field-subscript();
  }

  ninety-attachments {
    // In a perfect world, we'd be using the modern ninety-file-attachments component or at least the
    // vto-lists.scss styles. But this is VTO - hack our way to getting the component to fill the widget.
    mat-list {
      margin-left: -16px;
      width: calc(100% + 32px);
    }
  }
}

ninety-future-year {
  mat-card-content {
    @include remove-form-field-subscript();

    // Make all list content a flex container
    .mdc-list-item__primary-text {
      display: flex;
      align-items: center;
    }
  }

  // Rocks styling for when used as a content-child of the ninety-days future-year variant
  ninety-rocks {
    mat-card {
      box-shadow: terra.elevation(0) !important;
    }

    mat-toolbar.mat-toolbar.heading-title {
      // Bring Company/Departmental rocks header inline with rest of future-year headers
      padding-left: 0;
    }

    .company-rocks-card {
      // Prevents nested card outline on column view
      box-shadow: unset;
    }
  }
}

// Ensure flex styles of future-year children get applied when dragging/dropping
mat-list-item.flex-parent-while-dragging {
  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

ninety-manage-sections-dialog {
  h1.mdc-dialog__title {
    padding-left: 0;
  }

  // Make all list content a flex container
  .mdc-list-item__primary-text {
    height: 48px; // Fix weird visual case where title header gets cut off, but only on hidden sections?
    display: flex;
    align-items: center;
  }
}

// Everything bellow was migrated from styles.scss & should be considered deprecated. Write finer-grained styles
// or develop re-usable components/directives for shared functionality.
.vto-list {
  margin-bottom: 10px;

  .checklist-header {
    @include terra.text(body-bold);
  }

  .mdc-list-item__primary-text {
    display: flex;
  }

  mat-list-item.mat-mdc-list-item {
    height: auto;
    padding: 10px 0;
    @include terra.text(body);
  }

  &.core-values {
    mat-list-item.mat-mdc-list-item {
      div.mat-list-item-content {
        padding: 0;
      }
    }
  }

  .title {
    width: 200px;
    overflow-wrap: break-word;
    @media (max-width: 599px) {
      width: 100px;
    }
  }

  .description {
    flex: 1;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  ::ng-deep .mat-list-item-content {
    padding: 0 !important;
  }
}

// SWOT styles

ninety-swot {
  mat-list-item {
    // Force mat-list items in swot component to fill the available horizontal space
    // Targeting -unscoped-content over __primary-text in this case because an observer only inserts a single item in the list,
    // so the __primary-text class is not present.
    // As I see it, this class is present in both cases, so it should be safe to use.
    .mat-mdc-list-item-unscoped-content {
      @include vto-swot-list-style();
    }
  }
}

// Drag preview exists outside of `ninety-swot` in DOM so we must target it separately.
mat-list-item.swot-list-item.cdk-drag-preview {
  // Adjust padding to reduce layout shift on drag preview
  padding-left: 5px;
  .mat-mdc-list-item-unscoped-content {
    @include vto-swot-list-style();
  }
}

.swot-list-item-menu {
  // Vertically center and space icon from text in SWOT context menu
  .mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
    ninety-icon {
      margin-right: 10px;
    }
  }
}
