// Global module barrel file

/*
 * This global module should include anything needed globally for the application to work
 *
 * Resetting/normalizing of browser default styles should happen here
 *
 * This file should NOT define CSS rules/selectors but should only include other Sass partials which do
 */

// Reboot/Resets
// Used for resetting properties set by browser defaults
// Also can be where we reset/normalize existing legacy 90 global styles
@use '../globals/firma';

// Global helpers
// Limited set of global design system helpers (a11y, spacing, typography)
// example @use '../core/typography/typography.globals';
@use '../core/typography/typography.globals';
@use '../core/a11y/a11y.globals';

// Global Overrides
@use '../overrides/switch.overrides';

// Generic Globals
// For native elements, these are a step above a reset and start to add decisions about how we want native elements to behave
// Example would be adding the default background color for the body
// Partials should focus on just a single element
// Example:
// @use '../globals/html';
// @use '../globals/body';
