@use 'terra';
/*
  Completion Style Checkbox
*/
mat-checkbox.check-complete {
  // TODO(mdc-migration) Remove after migration
  .mat-checkbox-inner-container {
    height: 24px;
    width: 24px;
  }
  // TODO(mdc-migration) Remove after migration
  .mat-checkbox-background {
    border: 1px solid terra.color('border', 'heavy');
    box-sizing: border-box;
    @include terra.radius(huge);
    background-position: center;
    background-size: 14px 10px;
    background-repeat: no-repeat;
  }

  // TODO(mdc-migration) Remove after migration
  .mat-checkbox-frame {
    border: none;
  }

  // TODO(mdc-migration) Remove after migration
  .mat-checkbox-checkmark-path {
    display: none;
  }

  .mdc-checkbox {
    width: 24px;
    height: 24px;
    padding: 0px;
    margin: auto;
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 24px;

    .mdc-checkbox__native-control {
      width: 24px;
      height: 24px;
      left: 0px;
      top: 0px;
      &:focus ~ .mdc-checkbox__ripple {
        opacity: 0;
      }
    }

    .mdc-checkbox__ripple {
      width: 40px;
      height: 40px;
      top: -8px;
      bottom: -8px;
      right: -8px;
      left: -8px;
    }

    .mat-ripple {
      width: 40px;
      height: 40px;
      top: calc(50% - 20px);
      bottom: calc(50% - 20px);
      left: calc(50% - 20px);
      right: calc(50% - 20px);
      z-index: 1;
      background-color: transparent;
    }
    .mdc-checkbox__background {
      border: 1px solid terra.color('border', 'heavy') !important;
      box-sizing: border-box;
      @include terra.radius(huge);
      background-position: center;
      background-size: 14px 10px;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      top: 0px;
      left: 0px;
    }

    .mdc-checkbox__checkmark-path {
      display: none;
    }
  }
}

/*
  Unchecked/Enabled - Hover
*/
mat-checkbox.check-complete.mat-checkbox.mat-accent:hover {
  .mat-checkbox-background {
    background: terra.color('neutral-shader');
    background-image: url('../assets/images/dark-check-complete.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px 10px;
  }
}

mat-checkbox.check-complete.mat-mdc-checkbox.mat-accent:hover {
  .mdc-checkbox__background {
    background-color: terra.color('neutral-shader') !important;
    background-image: url('../assets/images/dark-check-complete.svg') !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px 10px;
  }
}

mat-checkbox.check-complete.mat-mdc-checkbox.mat-accent:not(.mat-mdc-checkbox-checked):has(
    .mdc-checkbox__native-control:hover
  ) {
  .mdc-checkbox__background {
    background-color: terra.color('neutral-shader') !important;
    background-image: url('../assets/images/dark-check-complete.svg') !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px 10px;
  }
}
/*
  Checked/Enabled
*/
mat-checkbox.check-complete.mat-checkbox-checked.mat-accent {
  .mat-checkbox-background {
    background-color: terra.color('green');
    border: none;
    background-image: url('../assets/images/check-complete.svg');
  }
}
mat-checkbox.check-complete.mat-mdc-checkbox-checked.mat-accent {
  .mdc-checkbox__background {
    background-color: terra.color('green') !important;
    border: none !important;
    background-image: url('../assets/images/check-complete.svg');
  }
}
/*
  Checked/Enabled - Hover
*/
mat-checkbox.check-complete.mat-checkbox-checked.mat-accent:hover {
  .mat-checkbox-background {
    background-color: terra.color('green');
    border: none;
    background-image: url('../assets/images/check-complete.svg');
  }
  .mat-checkbox-checkmark-path {
    display: none;
  }
}
mat-checkbox.check-complete.mat-mdc-checkbox-checked.mat-accent:hover {
  .mdc-checkbox__background {
    background-color: terra.color('green') !important;
    border: none !important;
    background-image: url('../assets/images/check-complete.svg') !important;
  }
  .mdc-checkbox__checkmark-path {
    display: none;
  }
}
/*
  Checked/Disabled
*/
mat-checkbox.check-complete.mat-checkbox-checked.mat-accent.mat-checkbox-disabled {
  .mat-checkbox-background {
    background-image: url('../assets/images/check-complete.svg') !important;
    background-color: terra.color('shadow', 'heaviest') !important;
    border: none;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 14px 10px !important;
  }
}
mat-checkbox.check-complete.mat-mdc-checkbox-checked.mat-accent.mat-mdc-checkbox-disabled {
  .mdc-checkbox__background {
    background-image: url('../assets/images/check-complete.svg') !important;
    background-color: terra.color('shadow', 'heaviest') !important;
    border: none;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 14px 10px !important;
  }
}
/*
  Unchecked/Disabled
*/
mat-checkbox.check-complete.mat-checkbox.mat-accent.mat-checkbox-disabled {
  .mat-checkbox-background {
    background: terra.color('neutral-shader');
  }
}

/*
  General
*/

.mdc-checkbox
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
  ~ .mdc-checkbox__background {
  background-color: terra.color('neutral-shader') !important;
}
