@use 'terra';

.conversation-dialog-panel div.mdc-dialog__surface {
  padding: 0;
}

// Force mat-button-wrapper styling for ninety-user-select label
ninety-create-conversation-participants-form {
  .manager-name {
    ninety-user-select {
      .create-conversation-manager-label {
        padding-left: 10px;
      }

      // Force flex alignment
      &.flex-with-label {
        width: 100%;

        .menu-btn {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

          .mat-button-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }
  }

  // Force removal of shadow (i.e. ripple)
  .remove-ripple {
    ninety-user-select {
      button {
        span[matripple] {
          content: none;
          display: none;
          visibility: hidden;
        }
      }
    }
  }
}

// Fix for conversation add user button shrinking after interaction DEV-9355
.create-conversation-section {
  ninety-user-select > ninety-button {
    display: block !important;
  }
}

.create-conversation-checkbox-row {
  padding-top: 30px;
  .mat-checkbox-frame {
    border-color: terra.color('border');
  }
}

.conversation-list-card .conversation-list-card-footer {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conversation-list-card {
  ninety-card-footer {
    border: 1px solid;
    border-color: terra.color('border', 'light');
  }
  mat-row {
    min-height: 56px;
  }
  mat-cell {
    border-color: terra.color('border', 'light');
  }
  .mat-mdc-header-cell {
    border-color: terra.color('border', 'light');
  }

  @media (max-width: 740px) {
    .mat-mdc-paginator-page-size {
      display: none;
    }
    mat-row {
      min-height: 50px;
    }
  }
  @media (max-width: 599px) {
    .conversation-list-card-footer {
      flex-direction: column-reverse;
      align-items: initial;
    }
  }
}

ninety-conversations,
ninety-create-conversation-dialog,
ninety-conversation-detail,
ninety-conversation-settings {
  .mat-mdc-tab .mdc-tab-indicator__content--underline,
  .mat-mdc-tab-link .mdc-tab-indicator__content--underline,
  .mat-mdc-tab.mdc-tab--active:hover .mdc-tab-indicator__content--underline,
  .mat-mdc-tab.mdc-tab--active:focus .mdc-tab-indicator__content--underline {
    border-color: terra.color('brand') !important;
  }
}

ninety-conversation-settings {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: terra.color('white');
  }

  mat-form-field {
    height: 70px;
  }
}

ninety-create-review {
  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
}

ninety-create-conversation-dialog {
  mat-tab-header.mat-mdc-tab-header {
    position: sticky;
    top: 0;
    z-index: 3;
    background: terra.color(white);
  }
}

ninety-create-conversation-participants-form,
ninety-create-conversation-settings-form {
  mat-checkbox {
    line-height: 21px;

    .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate ~ .mdc-checkbox__background {
      background-color: terra.color('brand') !important;
      border-color: terra.color('brand') !important;
    }

    .mdc-checkbox
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background {
      background-color: transparent !important;
      border-color: terra.color(text, light) !important;
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
      background-color: terra.color('brand') !important;
      border-color: terra.color('brand') !important;
    }
  }
}

ninety-formal-conversation-settings {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: terra.color('white') !important;
  }
}
