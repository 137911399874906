// Global styles for ninety-inline-editable-title
@use 'terra';
@use '@angular/material' as mat;

@import 'mixins';

ninety-inline-editable-title {
  &.truncate-text {
    .non-input-inline-title {
      @include ellipsis();
    }
  }

  .edit-btn {
    display: inline-block;
    visibility: hidden;
  }

  .edit-btn .ninety-svg-edit-icon {
    display: none;
  }

  &.editable-title-svg-icon > .edit-btn {
    .ninety-svg-edit-icon {
      display: inline;
    }
    .mat-svg-icon {
      display: none;
    }
  }
}

.list-item:hover .edit-btn,
.td-title:hover .edit-btn,
.mat-column-title:hover .edit-btn,
.mat-column-milestoneTitle:hover .edit-btn,
.ninety-td:hover .edit-btn {
  visibility: visible;
  background-color: transparent;
  @include terra.radius(small);
  border: none;
  padding: 0px;
  margin: auto 10px;
  width: 30px;
  height: 30px;
  color: terra.color('neutral-mid', '400');
  cursor: pointer;

  &:hover {
    color: terra.color('neutral-mid', '500');
  }
}
