@use 'terra';

ninety-learning-modules-page {
  #learning-modules-page {
    .message-box {
      width: 384px;
    }
  }
}

.details-route ninety-learning-modules-page {
  #learning-modules-page {
    height: 100% !important;
  }
  height: 100% !important;
}

ninety-learning-tasks-table {
  .mat-mdc-table {
    .task-indent {
      ninety-icon svg {
        width: 56px !important;
      }
    }
  }
}
