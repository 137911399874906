@use 'terra';
@import 'media-queries';

// Basic CSS
@import 'gridstack/dist/gridstack.min.css';

// CSS needed for column(N) where N < 12 - default only includes CSS for N = 12 and we use N = 3
// https://github.com/gridstack/gridstack.js#change-grid-columns
@import 'gridstack/dist/gridstack-extra.css';

$card-margin: 1px;
$resize-icon-height: 25px;

// Same concept and value as GridOptions.DEFAULT_SINGLE_COLUMN_BREAKPOINT. Updates here should also be made there.
$one-column-grid-cutoff: 1000px;

// Grid level selector
.grid-stack {
  background-color: transparent;
  // Make sure the bottom most grid item doesn't touch the bottom of the page
  padding-bottom: 10px;

  // Grid sections have a 8px inset, which acts like margin, creating extra unwanted spacing.
  // We use a negative margin to undo this.
  margin: -8px;

  box-sizing: content-box;

  // Style the placeholder shown where an item would go if you were to drop it while dragging
  .grid-stack-placeholder .placeholder-content {
    background: terra.color('neutral-shader'); // Override default: transparent
    @include terra.radius(small); // Match .grid-stack-item
  }

  // Item level selector - class applied directly to host.
  ninety-grid-item.grid-stack-item {
    /* Hide the SW resize icon
     * For some reason, when you turn on resize handles in every direction, the icon is only added in the SW corner. The
     * override is correctly applied, meaning you can do resize in any direction. But in addition to the always present
     * SE corner icon, one is shown in the SW corner....nasty. Note, it must still be present for the icon to change
     * which is why we remove the image instead of setting display: hidden
     */
    .ui-resizable-sw {
      background-image: none;
    }

    &.ui-draggable {
      .grid-stack-item-content {
        // On hover, call attention to drag/drop ability
        &:hover {
          box-shadow: 0 0 2px 2px terra.color('brand');
          @include terra.radius(small);
          cursor: grab;
        }

        // The footer is used to make sure no scrollbar is positioned over/under the resize icon. It is only showed when
        // layout mode is turned on. Most noticeable/impactful in Chrome.
        .grid-item-draggable-icon-footer {
          display: unset;
          height: $resize-icon-height;
        }

        // Make space for the footer
        .grid-item-scrollable-content {
          position: relative;
          height: calc(100% - $resize-icon-height);
        }
      }
    }

    // Content + footer selector
    .grid-stack-item-content {
      // Set background-color to "hack" our way around empty cards not filling entire grid-stack-item
      background-color: terra.color('white');

      // Override mat-card box-shadow
      @include terra.elevation(2);

      .grid-item-draggable-icon-footer {
        display: none;
      }

      // Scrollable content selector
      .grid-item-scrollable-content {
        overflow-y: auto;
        overflow-x: hidden;

        height: 100%;

        // Legacy VTO styling overrides for Material (Note, this is *not* the component ninety-card)
        .ninety-card {
          margin: $card-margin; // Remove card margin so dividers run the entire width of the section

          box-shadow: terra.elevation(0) !important; // Hide the mat-card shadow as we add our own above
          height: calc(100% - (2 * $card-margin)); // Need the margin above, but need to account for it in the height

          // Set to 1300 in styles.scss which will cause the card to not fill a large screen when it takes up more than
          // 1300px
          max-width: unset;
        }
      }
    }
  }
}

// Need to make sure the layout buttons have a non-grey color on the partner-hub
ninety-vto-settings {
  ninety-grid-layout-toolbar-buttons {
    .mat-button,
    .mat-icon-button,
    .mat-stroked-button,
    .mat-flat-button {
      color: terra.color('black');
    }
  }
}

// When the grid is displaying its singleton component, ensure the widget fills the available space
.grid-alternate {
  // Configure container
  display: flex;
  width: 100%;

  &.multiple {
    flex-direction: column;

    ninety-grid-item {
      padding-bottom: 16px; // GridStack normally adds 8px of margin to widgets, here we add our own in one-col
    }

    // Since the skeleton loader replaces the underlying card, it will have no height. Thus we set some here. Only
    // relevant when in singleton mode and the widget is loading
    // TODO remove this after transitioning the skeleton loader to an overlay
    .ninety-skeleton-parent-container {
      min-height: 345px; // Set to height of widget with 5 items as a decent default
      width: 100%;
    }
  }

  &.singleton {
    // Since the skeleton loader replaces the underlying card, it will have no height. Thus we set some here. Only
    // relevant when in singleton mode and the widget is loading
    // TODO remove this after transitioning the skeleton loader to an overlay
    .ninety-skeleton-parent-container {
      min-height: 1000px; // Set to height of widget with 10 items as a decent default
      width: 100%;
    }
  }

  // Configure all child elements to use flex
  > ninety-grid-item,
  > ninety-grid-item > .grid-stack-item-content,
  > ninety-grid-item > .grid-stack-item-content > .grid-item-scrollable-content,
  > ninety-grid-item > .grid-stack-item-content > .grid-item-scrollable-content ninety-grid-section,
  > ninety-grid-item > .grid-stack-item-content > .grid-item-scrollable-content ninety-grid-section .grid-section,
  > ninety-grid-item > .grid-stack-item-content > .grid-item-scrollable-content ninety-grid-section .grid-section > *,
  > ninety-grid-item
    > .grid-stack-item-content
    > .grid-item-scrollable-content
    ninety-grid-section
    .grid-section
    > *
    ninety-card {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
